import {
  boolean,
  nonEmptyArray,
  option,
  readerTaskEither,
  taskEither,
} from "fp-ts";
import {
  constFalse,
  constNull,
  constTrue,
  constVoid,
  flow,
  pipe,
} from "fp-ts/function";
import { Option } from "fp-ts/Option";
import {
  Box,
  ErrorBanner,
  FeedbackBlock,
  Loader,
  LocalizedString,
  useAuthenticationContext,
} from "design-system";
import { LocaleKey, useFormatMessage } from "../intl";
import { useCommand } from "../useAPI";
import { MainLayout } from "../Common/MainLayout/MainLayout";
import * as headerActionIcon from "../Common/MainContent/headerActionIcon";

import { PhoneAndEmailVerification } from "./PhoneAndEmailVerification/PhoneAndEmailVerification";
import * as phoneAndEmailVerificationApi from "../PhoneAndEmailVerification/api";
import { PortalStatusAlert } from "../Common/PortalStatusAlert/PortalStatusAlert";
import { useEffect, useReducer, useState } from "react";
import {
  accountsForRefinancingAction,
  Action,
  applicationLockedAction,
  checkExpensesOffersAction,
  creditChecksAction,
  customerOfferAction,
  emptyParallelFlowParams,
  emptyRework,
  errorAction,
  existingApplication3PErrorAction,
  existingClientAction,
  existingClientSuspenseAction,
  expensesAndAdditionalIncomeAction,
  expensesAndAdditionalIncomeAction2,
  expensesAndAdditionalIncomeReworkAction,
  foldState,
  followUpAndSignatureAction,
  goBackAction,
  goToStepAction,
  kycAction,
  landing,
  landingAction,
  loading,
  maxNumberChangesReachedAction,
  micropaymentAction,
  micropaymentOverviewAction,
  offerListAction,
  offerListReworkAction,
  offerReviewAction,
  packageSelectionAction,
  reasonNotSavedOnExit,
  reducer,
  rejectAction,
  RejectionReason,
  reloadAction,
  restoreApplicationAction,
  restoreStepAction,
  Rework,
  saveCustomerOfferSuspenseAction,
  select3PAction,
  sendAdditionalIncomeAction,
  shouldShowFormsOnExit,
  State,
  summaryAction,
  uploadDocumentsAction,
  uploadIdAction,
  verifyPhoneAndEmailAction,
  virtualCardAction,
} from "./StandardLoanState";
import { Landing } from "./Landing/Landing";
import * as api from "./api";
import { ParallelFlowParameters } from "./api";
import * as reworkApi from "./Rework/api";
import { UploadId } from "./UploadId/UploadId";
import { CreditChecks } from "./CreditChecks/CreditChecks";
import { ExistingClient } from "./ExistingClient/ExistingClient";
import { RestoreDialog } from "./RestoreDialog";
import { OfferList } from "./OfferList/OfferList";
import { KYC } from "./KYC/KYC";
import { FinalFeedback } from "./Results/FinalFeedback";
import ExitProcessDialogWrapper from "./ExitProcessDialogWrapper";
import { CustomerOffer } from "./CustomerOffer/CustomerOffer";
import { ExpensesAndAdditionalIncome } from "./ExpensesAndAdditionalIncome/ExpensesAndAdditionalIncome";
import {
  CredentialStatus,
  FollowUpAndSignature,
} from "./FollowUpAndSignature/FollowUpAndSignature";
import { OfferReview } from "./OfferReview/OfferReview";
import {
  foldFlowType,
  RestoredRecapData,
  StandardLoanFlowType,
  UnderAgeError,
  mapSignatureToExistingClientAuthMethod,
} from "./domain";
import { UploadRequiredDocuments } from "./UploadDocuments/UploadDocuments";
import { ExistingApplication3PError } from "./ExistingApplication3PError/ExistingApplication3PError";
import { AccountsForRefinancing } from "./Refinancing/AccountsForRefinancing";
import { MicroTransaction } from "./MicroTransaction/MicroTransaction";
import { ContractsSent } from "./ContractsSent/ContractsSent";
import { ExistingClientAuthenticationMethod } from "../globalDomain";
import { UserValidationWrapper } from "./UserValidationWrapper";
import { TaskEither } from "fp-ts/TaskEither";
import { useFlowTypeCases } from "./useFlowTypeCases";
import { useSteps } from "./useSteps";
import {
  foldRestoreDataType,
  RestoreDataObjectSL,
} from "../Common/OpenLoanApplicationWrapper/domain";
import { SLSummary } from "./Results/SLSummary";
import { StartNewFlow } from "../Common/StartNewFlow";
import { NonEmptyArray } from "fp-ts/NonEmptyArray";
import { ConfirmClientData } from "./Rework/ConfirmClientData";
import { MicroTransactionOverview } from "./MicroTransaction/MicroTransactionOverview";
import { AuthMethodCalculateBySignatureType } from "../Common/AuthMethodCalculateBySignatureType";
import {
  ApplicationStatus,
  SLApplicationStatusFinished,
} from "../Common/ProcessStoreList/api";
import { OfferReviewPush } from "./OfferReview/OfferReviewPush";
import { ReloadSuspense } from "./ReloadSuspense";
import { ExistingClientSuspense } from "./ExistingClient/ExistingClientSuspense";
import { AdditionalIncomesCheck } from "./ExpensesAndAdditionalIncome/AdditionalIncome/AdditionalIncomesCheck";
import { CheckExpensesOffers } from "./OfferList/CheckExpensesOffers";
import { SendAdditionalIncome } from "./OfferList/SendAdditionalIncome";
import { useAppContext } from "../useAppContext";
import { useIs3PChannel, useIsInPersonChannel } from "../useChannel";
import * as uploadDocumentsApi from "./UploadDocuments/api";
import { AfterSignature } from "./FollowUpAndSignature/LowRiskClient/AfterSignature";
import { SaveCustomerOfferSuspense } from "./ExpensesAndAdditionalIncome/SaveCustomerOfferSuspense";
import { ContactChange } from "../ClientProfile/ContactChange";
import Select3P from "./Select3P/Select3P";
import { MainContent } from "../Common/MainContent";
import { ApplicationLockedPopup } from "../Common/Dialogs/ApplicationLockedPopup";
import { IncomeSourceType } from "./IncomeForm/domain";
import { PackagesSelection } from "../PackagesSelection/PackagesSelection";
import { VirtualCardsSL } from "./VirtualCards/VirtualCardsSL";
import { PackageType } from "../PackagesSelection/api";
import { GDPR } from "../GDPR/GDPR";

type Props = {
  onExit: Option<
    (isApplicationSaved: boolean, reasonNotSaved?: LocaleKey) => unknown
  >;
  onClientExists: (findABranch: boolean) => unknown;
  flowType: StandardLoanFlowType;
  authenticationMethod: Option<ExistingClientAuthenticationMethod>;
  initialLoanOffer: Option<api.LoanOffer>;
  onPhoneVerified: Option<TaskEither<unknown, unknown>>;
  restoreDataObject: Option<RestoreDataObjectSL>;
  onAgeValidationFail: Option<() => unknown>;
  applicationStatus: Option<ApplicationStatus>;
  isProspectClient?: boolean;
  isCredentialsAndSigningRemote?: boolean;
  on3PExistingClient?: (loanOffer: Option<api.LoanOffer>) => unknown;
  clientExists: boolean;
};

export default function StandardLoan(props: Props) {
  const formatMessage = useFormatMessage();
  const { startNewFlow } = useAuthenticationContext();
  const isInPersonChannel = useIsInPersonChannel();
  const is3PChannel = useIs3PChannel();

  const [isExiting, setIsExiting] = useState(false);
  const [isCreditChecksConfirmed, setIsCreditChecksConfirmed] = useState(false);
  const [currentLoanOffer, setCurrentLoanOffer] = useState<
    Option<api.LoanOffer>
  >(props.initialLoanOffer);
  const startApplication = useCommand(api.startApplication);
  const requiredDocuments = useCommand(api.requiredDocuments);
  const [state, dispatch] = useReducer(reducer, loading());

  const [existingClient, setExistingClient] = useState(
    pipe(
      props.restoreDataObject,
      option.map(r => r.restoreData.existingClient)
    )
  );

  const [incomeSourceType, setIncomeSourceType] = useState("Employed");

  const { isPWSRemote, isTLS, is3P } = useFlowTypeCases(props.flowType);

  const {
    apiParameters: { tenant },
    config: { unicreditBranchesMapURL, enableVirtualCardsLoanImpl },
  } = useAppContext();

  const generateOtp = useCommand(phoneAndEmailVerificationApi.otpGeneration);
  const verifyOtp = useCommand(phoneAndEmailVerificationApi.otpVerify);
  const checkExistingApplication = useCommand(api.checkExistingApplication);
  const sendMemorandum = useCommand(api.sendMemorandum);
  const getNewestApplication = useCommand(api.newestApplicationInProgress);
  const isApplicationLocked = useCommand(api.isApplicationLocked);
  const restoreApplication = useCommand(api.restoreNewestApplication);
  const deleteCurrentApplication = useCommand(api.deleteApplication);
  const sendFeedback = useCommand(api.exitProcess);
  const saveEmailUploadLinkData = useCommand(api.saveEmailUploadLinkData);
  const areExternalLiabilitiesSelected = useCommand(
    api.areExternalLiabilitiesSelected
  );
  const isKycNeeded = useCommand(api.isKycNeeded);
  const getIsCBRejected = useCommand(api.getIsCBRejected);
  const updateLoanOffer = useCommand(api.updateLoanOffer);
  const getReworkDetails = useCommand(reworkApi.reworkDetails);
  const getReworkOldValues = useCommand(reworkApi.getReworkOldValues);
  const getClientExisting = useCommand(api.isExistingClient);
  const resetCredentialsProcess = useCommand(api.resetCredentialsProcess);
  const confirmUpload = useCommand(uploadDocumentsApi.confirmUpload);
  const getSignatureStatus = useCommand(api.getSignatureStatus);
  const initDefaults3P = useCommand(api.initDefaults3P);

  const saveApplication = useCommand(api.saveApplication);
  const closeApplication = useCommand(api.closeApplication);

  const hasParallelFlow = useCommand(api.hasParallelFlow);
  const getRequiredDocumentsReceived = useCommand(api.checkRequiredDocuments);

  const choosePackageType = useCommand(api.choosePackageType);
  const savePhysicalCard = useCommand(api.savePhysicalCardValue);
  const virtualCardNext = useCommand(api.virtualCardNext);
  const transitionToMasterCard = useCommand(api.transitionToMasterCard);

  const isClientFlow = ["SmartBanking", "HomeBanking"].some(
    flowType => flowType === props.flowType
  );

  const isExistingClient = pipe(
    existingClient,
    option.getOrElse(() => props.clientExists)
  );

  const isRework = pipe(
    props.restoreDataObject,
    option.map(r => r.restoreData.festep === "UNDER_REWORK"),
    option.getOrElse(constFalse)
  );

  const { getSteps, goToStep } = useSteps({
    flowType: props.flowType,
    state,
    dispatch,
    stepVisibility: stepVisibilityFromReworkSteps(state),
    isRework: isRework,
    isExistingClient,
  });

  window.onunload = function () {
    if (shouldShowFormsOnExit(state, isCreditChecksConfirmed)) {
      saveApplication()();
    } else {
      closeApplication()();
    }
    window.onunload = null;
  };

  const dispatchWithRejectionCheck = (action: Action) => {
    pipe(
      getIsCBRejected(),
      taskEither.chain(({ cbRejected }) => {
        return taskEither.fromIO(() =>
          pipe(
            cbRejected,
            boolean.fold(
              () => dispatch(action),
              () => dispatch(rejectAction("CBRejected"))
            )
          )
        );
      })
    )();
  };

  const handleKyc = (hasRefinancingCredits: boolean) => {
    return pipe(
      props.authenticationMethod,
      option.fold(
        () =>
          taskEither.fromIO(() =>
            dispatchWithRejectionCheck(kycAction(hasRefinancingCredits))
          ),
        () =>
          pipe(
            isKycNeeded(),
            taskEither.chain(({ isKycNeeded }) =>
              taskEither.fromIO(() =>
                dispatchWithRejectionCheck(
                  isKycNeeded
                    ? kycAction(hasRefinancingCredits)
                    : //TODO: for TLS, should this go to micropayment before signature?
                      followUpAndSignatureAction(
                        hasRefinancingCredits,
                        isKycNeeded
                      )
                )
              )
            )
          )
      )
    );
  };

  const handleAccountsForRefinancingAndKYC = (hasRefinancingCredits: boolean) =>
    pipe(
      props.restoreDataObject,
      option.fold(
        constFalse,
        restoreData => restoreData.restoreData.festep === "UNDER_REWORK"
      ),
      boolean.fold(
        () =>
          pipe(
            areExternalLiabilitiesSelected(),
            taskEither.chain(({ externalLiabilities }) => {
              if (externalLiabilities) {
                return taskEither.fromIO(() =>
                  dispatchWithRejectionCheck(
                    accountsForRefinancingAction(hasRefinancingCredits)
                  )
                );
              } else {
                return handleKyc(hasRefinancingCredits);
              }
            })
          ),
        () =>
          taskEither.fromIO(() => dispatch(goToStepAction("ReworkNextStep")))
      )
    );

  const handlePackageSelection = (loanOffer: api.LoanOffer) =>
    pipe(
      props.restoreDataObject,
      option.fold(
        constFalse,
        restoreData => restoreData.restoreData.festep === "UNDER_REWORK"
      ),
      boolean.fold(
        () =>
          taskEither.fromIO(() =>
            dispatchWithRejectionCheck(
              packageSelectionAction(loanOffer, option.none)
            )
          ),
        () =>
          taskEither.fromIO(() => dispatch(goToStepAction("ReworkNextStep")))
      )
    );

  const handleAfterOfferReview = (
    hasRefinancingCredits: boolean,
    loanOffer: api.LoanOffer
  ) => {
    if (
      option.isSome(props.applicationStatus) &&
      props.applicationStatus.value === "PENDING_SIGNATURE"
    ) {
      //user restored the application from link, it has all steps confirmed except for signing
      return taskEither.fromIO(() =>
        dispatchWithRejectionCheck(
          followUpAndSignatureAction(hasRefinancingCredits, false, false)
        )
      );
    }

    return enableVirtualCardsLoanImpl && !isExistingClient
      ? handlePackageSelection(loanOffer)
      : handleAccountsForRefinancingAndKYC(hasRefinancingCredits);
  };

  const onChooseCard = (packageType: PackageType, loanOffer: api.LoanOffer) => {
    return pipe(
      choosePackageType({ type: packageType }),
      taskEither.chain(() =>
        taskEither.fromIO(() =>
          dispatchWithRejectionCheck(virtualCardAction(loanOffer))
        )
      ),
      taskEither.mapLeft(constVoid)
    );
  };

  const handleAfterVirtualCard = (hasRefinancingCredits: boolean) => {
    return pipe(
      virtualCardNext(),
      taskEither.chain(() =>
        handleAccountsForRefinancingAndKYC(hasRefinancingCredits)
      ),
      taskEither.mapLeft(constVoid)
    );
  };

  const handleError: (
    errors: UnderAgeError | unknown
  ) => UnderAgeError | unknown = errors => {
    return errors;
  };

  const navigateToFindBranches = () => {
    window.location.href = unicreditBranchesMapURL;
  };

  const runStartApplication = () =>
    pipe(
      startApplication(),
      taskEither.mapLeft(handleError),
      taskEither.chain(defaultOffer =>
        pipe(
          currentLoanOffer,
          option.fold(
            () =>
              taskEither.fromIO(() =>
                dispatch(verifyPhoneAndEmailAction(option.some(defaultOffer)))
              ),
            initialOffer =>
              pipe(
                updateLoanOffer({
                  amount: option.some(initialOffer.amount),
                  tenor: option.some(initialOffer.tenor),
                }),
                taskEither.chain(offer =>
                  taskEither.fromIO(() =>
                    dispatch(verifyPhoneAndEmailAction(option.some(offer)))
                  )
                )
              )
          )
        )
      )
    );

  const dispatchFirstStep = (
    loanOffer: Option<api.LoanOffer>
  ): TaskEither<unknown, void> => {
    return pipe(
      isPWSRemote,
      boolean.fold(
        () =>
          pipe(
            is3P,
            boolean.fold(
              () => taskEither.fromIO(() => dispatch(landingAction())),
              () =>
                option.isSome(loanOffer)
                  ? startCreditChecks3PExistingClient(loanOffer.value)
                  : taskEither.fromIO(() => dispatch(landingAction()))
            )
          ),
        () =>
          taskEither.fromIO(() =>
            dispatch(verifyPhoneAndEmailAction(option.none))
          )
      )
    );
  };

  const isApplicationFinished = (
    applicationStatus: Option<ApplicationStatus>
  ) =>
    pipe(
      applicationStatus,
      option.fold(constFalse, status => SLApplicationStatusFinished.is(status))
    );

  const getReworkData = (
    restoredData: api.RestoreNewestLoanApplicationOutput
  ) =>
    pipe(
      getReworkDetails(),
      taskEither.map(reworkSteps => ({
        reworkSteps: option.some(reworkSteps.steps),
        oldValues: option.none,
        newValues: option.some(
          restoredData as api.RestoreNewestLoanApplicationOtherStepsOutput
        ),
        needContractsUpdate: reworkSteps.needContractsUpdate,
        reworkAll: reworkSteps.reworkAll,
      })),
      taskEither.chain(rework =>
        pipe(
          getReworkOldValues(),
          taskEither.map(oldValues => ({
            ...rework,
            oldValues: option.some(oldValues),
          }))
        )
      )
    );

  const dispatchRework = (
    restoredData: api.RestoreNewestLoanApplicationOutput,
    loanOffer: Option<api.LoanOffer>,
    parallelFlowParameters: ParallelFlowParameters,
    applicationStatus: Option<ApplicationStatus>,
    cardProviderChange: boolean
  ) =>
    pipe(
      restoredData.festep === "AUTHORIZE_PUSH" ||
        restoredData.festep === "REMOTE_SIGNATURE",
      boolean.fold(
        () =>
          pipe(
            isApplicationFinished(applicationStatus),
            boolean.fold(
              () =>
                pipe(
                  restoredData,
                  option.fromPredicate(
                    () => restoredData.festep === "UNDER_REWORK"
                  ),
                  option.fold(
                    () =>
                      taskEither.fromIO(() => {
                        dispatch(
                          restoreApplicationAction({
                            restoredData,
                            applicationStatus,
                            loanOffer,
                            cardProviderChange,
                            rework: emptyRework,
                            isErrorFetchingRestoredData: false,
                            parallelFlowParameters: parallelFlowParameters,
                          })
                        ); // NO REWORK
                      }),
                    () =>
                      pipe(
                        getReworkData(restoredData),
                        taskEither.fold(
                          () => dispatchFirstStep(loanOffer),
                          rework =>
                            onRestoreApplication(restoredData, rework, true)
                        )
                      )
                  )
                ),
              () =>
                taskEither.fromIO(() =>
                  //application is finished, show final screen
                  dispatch(
                    restoreStepAction(
                      restoredData,
                      false,
                      emptyRework,
                      props.applicationStatus,
                      "NONE"
                    )
                  )
                )
            )
          ),
        () =>
          taskEither.fromIO(() =>
            dispatch(
              restoreApplicationAction({
                restoredData,
                applicationStatus,
                loanOffer,
                cardProviderChange,
                rework: emptyRework,
                isErrorFetchingRestoredData: false,
                parallelFlowParameters: parallelFlowParameters,
              })
            )
          )
      )
    );

  const checkRestoredData = (
    restoreDataOption: Option<api.RestoreNewestLoanApplicationOutput>,
    loanOffer: Option<api.LoanOffer>,
    parallelFlowParameters: ParallelFlowParameters,
    applicationStatus: Option<ApplicationStatus>,
    cardProviderChange: boolean
  ): TaskEither<unknown, void> => {
    const hasParallelFlow =
      parallelFlowParameters.hasCAFlowInProgress ||
      parallelFlowParameters.hasCAFlowSentToBO ||
      parallelFlowParameters.hasCFFlowSentToBO;

    if (hasParallelFlow) {
      return taskEither.fromIO(() => {
        dispatch(
          restoreApplicationAction({
            isErrorFetchingRestoredData: true,
            loanOffer: loanOffer,
            parallelFlowParameters: parallelFlowParameters,
          })
        );
      });
    }

    return pipe(
      restoreDataOption,
      option.fold(
        () => dispatchFirstStep(loanOffer),
        restoredData =>
          dispatchRework(
            restoredData,
            loanOffer,
            parallelFlowParameters,
            applicationStatus,
            cardProviderChange
          )
      )
    );
  };

  const checkNewestApplication = (
    loanOffer: Option<api.LoanOffer>,
    restoreData: Option<api.RestoreNewestLoanApplicationOutput>
  ): TaskEither<unknown, void> =>
    pipe(
      getNewestApplication(),
      taskEither.fold(
        error =>
          taskEither.fromIO(() => {
            const status = "status" in error && error.status;
            if (status === "TOO_EARLY") {
              return dispatch(rejectAction("RestoredTooEarly"));
            }
            return dispatch(
              restoreApplicationAction({
                isErrorFetchingRestoredData: true,
                parallelFlowParameters: emptyParallelFlowParams,
                loanOffer,
              })
            );
          }),
        restoreDataWrapper => {
          const correctRestoreData = option.isSome(restoreData)
            ? restoreData
            : restoreDataWrapper.restoreData;

          setExistingClient(
            pipe(
              correctRestoreData,
              option.map(r => r.existingClient)
            )
          );

          const correctApplicationStatus = option.isSome(
            props.applicationStatus
          )
            ? props.applicationStatus
            : restoreDataWrapper.status;

          return pipe(
            restoreDataWrapper.applicationId,
            option.fold(
              () => taskEither.right({ locked: false }),
              applicationId => isApplicationLocked({ applicationId })
            ),
            taskEither.chain(({ locked }) => {
              if (locked) {
                return taskEither.fromIO(() =>
                  dispatch(applicationLockedAction())
                );
              } else {
                return pipe(
                  correctApplicationStatus,
                  option.chain(
                    option.fromPredicate(status => status === "UNDER_REWORK")
                  ),
                  option.fold(
                    () =>
                      pipe(
                        is3P,
                        boolean.fold(
                          () =>
                            checkRestoredData(
                              correctRestoreData,
                              loanOffer,
                              restoreDataWrapper.parallelFlowParameters,
                              correctApplicationStatus,
                              restoreDataWrapper.cardProviderChange
                            ),
                          () =>
                            pipe(
                              restoreDataWrapper.responseWithDifferentBroker,
                              boolean.fold(
                                () =>
                                  checkRestoredData(
                                    correctRestoreData,
                                    loanOffer,
                                    restoreDataWrapper.parallelFlowParameters,
                                    correctApplicationStatus,
                                    restoreDataWrapper.cardProviderChange
                                  ),
                                () =>
                                  taskEither.fromIO(() =>
                                    dispatch(existingApplication3PErrorAction())
                                  )
                              )
                            )
                        )
                      ),
                    () => {
                      return pipe(
                        correctRestoreData,
                        option.fold(
                          () =>
                            taskEither.fromIO(() =>
                              dispatch(
                                restoreApplicationAction({
                                  isErrorFetchingRestoredData: true,
                                  parallelFlowParameters:
                                    restoreDataWrapper.parallelFlowParameters,
                                  loanOffer,
                                })
                              )
                            ),
                          restoredData =>
                            taskEither.fromIO(() =>
                              dispatch(
                                restoreApplicationAction({
                                  restoredData,
                                  applicationStatus: correctApplicationStatus,
                                  loanOffer,
                                  rework: emptyRework,
                                  isErrorFetchingRestoredData: false,
                                  parallelFlowParameters:
                                    restoreDataWrapper.parallelFlowParameters,
                                  cardProviderChange:
                                    restoreDataWrapper.cardProviderChange,
                                })
                              )
                            )
                        )
                      );
                    }
                  )
                );
              }
            })
          );
        }
      )
    );

  const startCreditChecks = (
    loanOffer: api.LoanOffer
  ): TaskEither<unknown, void> => {
    return pipe(
      getClientExisting(),
      taskEither.chain(data =>
        taskEither.fromIO(() =>
          setExistingClient(option.some(data.existingClient))
        )
      ),
      taskEither.chain(sendMemorandum),
      taskEither.chain(() =>
        taskEither.fromIO(() => dispatch(creditChecksAction(loanOffer)))
      )
    );
  };

  const checkParallelFlow = (
    loanOffer: api.LoanOffer
  ): TaskEither<unknown, void> =>
    pipe(
      hasParallelFlow(),
      taskEither.bimap(
        () => {
          is3P
            ? startCreditChecks3P(loanOffer)()
            : startCreditChecks(loanOffer)();
        },
        parallelFlowParams =>
          pipe(
            parallelFlowParams,
            option.fold(
              () => {
                is3P
                  ? startCreditChecks3P(loanOffer)()
                  : startCreditChecks(loanOffer)();
              },
              params => {
                if (
                  params.hasCFFlowSentToBO ||
                  params.hasCAFlowSentToBO ||
                  params.hasCAFlowInProgress
                ) {
                  dispatch(
                    restoreApplicationAction({
                      isErrorFetchingRestoredData: true,
                      loanOffer: option.some(loanOffer),
                      parallelFlowParameters: params,
                    })
                  ); // NO REWORK
                } else {
                  is3P
                    ? startCreditChecks3P(loanOffer)()
                    : startCreditChecks(loanOffer)();
                }
              }
            )
          )
      )
    );

  const startCreditChecks3PExistingClient = (loanOffer: api.LoanOffer) =>
    pipe(
      initDefaults3P(),
      taskEither.chain(() => taskEither.fromIO(constVoid)),
      taskEither.chain(sendMemorandum),
      taskEither.chain(() =>
        taskEither.fromIO(() => dispatch(creditChecksAction(loanOffer)))
      )
    );

  const startCreditChecks3P = (loanOffer: api.LoanOffer) =>
    pipe(
      getClientExisting(),
      taskEither.chain(data =>
        pipe(
          data.existingClient,
          boolean.fold(
            () =>
              pipe(
                taskEither.fromIO(() =>
                  setExistingClient(option.some(data.existingClient))
                ),
                taskEither.chain(sendMemorandum),
                taskEither.chain(() =>
                  taskEither.fromIO(() =>
                    dispatch(creditChecksAction(loanOffer))
                  )
                )
              ),
            () =>
              taskEither.fromIO(() =>
                dispatch(
                  existingClientSuspenseAction(option.some(loanOffer), false)
                )
              )
          )
        )
      )
    );

  const onCompleteUploadId = (loanOffer: api.LoanOffer) =>
    pipe(
      isPWSRemote,
      boolean.fold(
        () =>
          pipe(
            checkExistingApplication(),
            taskEither.chain(
              ({ appInitiatedBySame3PUserId, applicationExists }) =>
                pipe(
                  applicationExists,
                  boolean.fold(
                    () => checkParallelFlow(loanOffer),
                    () =>
                      pipe(
                        is3P,
                        boolean.fold(
                          () =>
                            checkNewestApplication(
                              option.some(loanOffer),
                              option.none
                            ),
                          () =>
                            pipe(
                              appInitiatedBySame3PUserId,
                              boolean.fold(
                                () =>
                                  taskEither.fromIO(() =>
                                    dispatch(existingApplication3PErrorAction())
                                  ),
                                () =>
                                  taskEither.fromIO(() =>
                                    dispatch(
                                      existingClientSuspenseAction(
                                        option.some(loanOffer),
                                        true
                                      )
                                    )
                                  )
                              )
                            )
                        )
                      )
                  )
                )
            )
          ),
        () =>
          pipe(
            checkExistingApplication(),
            taskEither.chain(({ applicationExists }) =>
              pipe(
                applicationExists,
                boolean.fold(
                  () => checkParallelFlow(loanOffer),
                  () =>
                    checkNewestApplication(option.some(loanOffer), option.none)
                )
              )
            )
          )
      )
    );

  // const selectedOfferCommand = useCommand(getSelectedOffer);

  const retrieveIsKycNeeded = (
    feStep: api.RestoreNewestLoanApplicationOutput["festep"]
  ): TaskEither<unknown, boolean> => {
    switch (feStep) {
      case "CREDIT_CHECKS":
      case "REVIEW_INFORMATION":
      case "FINALISE_OFFER_MAIN_SCREEN":
      case "EXPENSE":
      case "ADDITIONAL_INCOME":
      case "CHOOSE_OFFER":
      case "REVIEW_OFFER":
      case "AUTHORIZE_PUSH":
      case "REMOTE_SIGNATURE":
      case "FINAL_PAGE":
      case "UNDER_REWORK":
        return taskEither.fromIO(() => false);
      case "KYC":
        return taskEither.fromIO(() => true);
      case "PERSONAL_DATA":
      case "MICROPAYMENT":
      case "PACKAGE_SELECTION":
      case "VIRTUAL_CARDS":
      case "ACCOUNTS_FOR_REFINANCING":
      case "CLIENT_CREDENTIALS":
      case "UPLOAD_DOCUMENTS":
      case "SIGNATURE":
        return pipe(
          isKycNeeded(),
          taskEither.chain(response =>
            taskEither.fromIO(() => response.isKycNeeded)
          )
        );
    }
  };

  const onRestoreApplication = (
    restoredData: api.RestoreNewestLoanApplicationOutput,
    rework: Rework,
    restoreWithDialog: boolean
  ) =>
    pipe(
      props.restoreDataObject,
      option.fold(
        () => restoreApplication({ restoreWithDialog: restoreWithDialog }),
        restoreDataObject => {
          if (restoreDataObject.restoreDataType === "latest") {
            return restoreApplication({ restoreWithDialog: restoreWithDialog });
          }
          return taskEither.fromIO(constVoid);
        }
      ),
      taskEither.fold(
        error => {
          const status = "status" in error && error.status;
          if (status === "TOO_EARLY") {
            return taskEither.fromIO(() =>
              dispatch(rejectAction("RestoredTooEarly"))
            );
          }
          return taskEither.fromIO(() => dispatch(landing())); // KOPage?
        },
        () =>
          pipe(
            restoredData.festep === "UPLOAD_DOCUMENTS",
            boolean.fold(
              () => taskEither.fromIO(constVoid),
              () => saveEmailUploadLinkData()
            ),
            taskEither.chain(() =>
              pipe(
                restoredData.festep === "REVIEW_INFORMATION",
                boolean.fold(
                  () => taskEither.fromIO(constVoid),
                  () =>
                    taskEither.fromIO(() => setIsCreditChecksConfirmed(true))
                )
              )
            ),
            taskEither.chain(() =>
              pipe(
                restoredData.festep === "UNDER_REWORK" &&
                  rework === emptyRework,
                boolean.fold(
                  () => taskEither.fromIO(constVoid),
                  () =>
                    pipe(
                      getReworkData(restoredData),
                      taskEither.fold(
                        () => taskEither.fromIO(constVoid),
                        reworkData => {
                          rework = reworkData;
                          return taskEither.fromIO(constVoid);
                        }
                      )
                    )
                )
              )
            ),
            taskEither.chain(() => {
              return pipe(
                getSignatureStatus(),
                taskEither.chain(signatureStatus =>
                  pipe(
                    retrieveIsKycNeeded(restoredData.festep),
                    taskEither.chain(isKycNeeded =>
                      taskEither.fromIO(() =>
                        dispatch(
                          restoreStepAction(
                            restoredData,
                            isKycNeeded,
                            rework,
                            props.applicationStatus,
                            signatureStatus.signatureStatus
                          )
                        )
                      )
                    )
                  )
                )
              );
            })
          )
      )
    );

  useEffect(() => {
    pipe(
      state.type === "Loading",
      boolean.fold(constVoid, () =>
        pipe(
          props.authenticationMethod,
          option.fold(
            props.flowType === "TLSAgent"
              ? checkNewestApplication(option.none, option.none)
              : dispatchFirstStep(props.initialLoanOffer),
            pipe(
              props.restoreDataObject,
              option.fold(
                () =>
                  props.flowType === "TLSAgent"
                    ? taskEither.fromIO(() =>
                        dispatch(
                          existingClientSuspenseAction(option.none, false)
                        )
                      )
                    : checkNewestApplication(
                        is3P ? props.initialLoanOffer : option.none,
                        option.none
                      ),
                restoreDataObject =>
                  foldRestoreDataType(restoreDataObject.restoreDataType, {
                    onById: () =>
                      dispatchRework(
                        restoreDataObject.restoreData,
                        option.none,
                        emptyParallelFlowParams,
                        props.applicationStatus,
                        !!restoreDataObject.restoreData.cardProviderChange
                      ),
                    onLatest: () =>
                      checkNewestApplication(
                        option.none,
                        option.some(restoreDataObject.restoreData)
                      ),
                  })
              )
            )
          )
        )
      )
    );
  }, [props.authenticationMethod, state.type]);

  const extractRecapData = (
    restoredData: api.RestoreNewestLoanApplicationOutput
  ): RestoredRecapData => ({
    loanAmount: {
      amount: restoredData.genericLoanResponse.totalAmount,
      currency: restoredData.genericLoanResponse.currency,
    },
    instalment: {
      amount: restoredData.genericLoanResponse.installment,
      currency: restoredData.genericLoanResponse.currency,
    },
    tenor: restoredData.genericLoanResponse.tenor,
    interestRate: restoredData.genericLoanResponse.interestRate,
    feStep: restoredData.festep as LocalizedString,
  });

  const goToCreditChecksWithMemorandum = (loanOffer: api.LoanOffer) =>
    pipe(
      getClientExisting(),
      taskEither.chain(data =>
        taskEither.fromIO(() =>
          setExistingClient(option.some(data.existingClient))
        )
      ),
      taskEither.chain(sendMemorandum),
      taskEither.chain(() =>
        taskEither.fromIO(() => dispatch(creditChecksAction(loanOffer)))
      )
    );

  const landingStep = (loanOffer: api.LoanOffer, show3P: boolean | undefined) =>
    show3P
      ? dispatch(select3PAction(loanOffer))
      : pipe(
          props.authenticationMethod,
          option.fold(() => {
            setCurrentLoanOffer(option.some(loanOffer));
            dispatch(verifyPhoneAndEmailAction(option.some(loanOffer)));
          }, goToCreditChecksWithMemorandum(loanOffer))
        );

  enum GDPR_STATE {
    NO,
    YES,
    PAPER_BASED,
  }
  const gdprType: GDPR_STATE = pipe(
    state,
    foldState({
      Loading: () => GDPR_STATE.NO,
      Error: () => GDPR_STATE.NO,
      ApplicationLocked: () => GDPR_STATE.NO,
      ExistingApplication3PError: () => GDPR_STATE.NO,
      Landing: () => GDPR_STATE.NO,
      AddPhoneNumber: () => GDPR_STATE.NO,
      PhoneAndEmailVerification: () => GDPR_STATE.NO,
      MaxNumberChangesReached: () => GDPR_STATE.NO,
      UploadID: () => GDPR_STATE.NO,
      ExistingClient: () => GDPR_STATE.NO,
      ExistingClientSuspense: () => GDPR_STATE.NO,
      FollowUpAndSignature: () => GDPR_STATE.NO,
      AccountsForRefinancing: () => GDPR_STATE.YES,
      CheckAdditionalIncomes: () => GDPR_STATE.YES,
      CheckExpensesOffers: () => GDPR_STATE.YES,
      CreditChecks: () => GDPR_STATE.YES,
      VirtualCard: () => GDPR_STATE.YES,
      CustomerOffer: () => GDPR_STATE.YES,
      ExpensesAndAdditionalIncome: () => GDPR_STATE.YES,
      KYC: () => GDPR_STATE.YES,
      Micropayment: () => GDPR_STATE.YES,
      MicropaymentOverview: () => GDPR_STATE.YES,
      OfferList: () => GDPR_STATE.YES,
      OfferReview: () => GDPR_STATE.YES,
      OfferReviewPush: () => GDPR_STATE.YES,
      PackageSelection: () => GDPR_STATE.YES,
      SendAdditionalIncome: () => GDPR_STATE.YES,
      PersonalData: () => GDPR_STATE.NO,
      Rejected: () => GDPR_STATE.NO,
      ReloadSuspense: () => GDPR_STATE.NO,
      RestoreApplication: () => GDPR_STATE.NO,
      SaveCustomerOfferSuspense: () => GDPR_STATE.NO,
      Select3P: () => GDPR_STATE.NO,
      UploadDocuments: () => GDPR_STATE.PAPER_BASED,
      AfterSignature: () => GDPR_STATE.PAPER_BASED,
      Summary: () => GDPR_STATE.PAPER_BASED,
    })
  );
  const [gdprDialogDisplayed, setGdprDialogDisplayed] = useState(false);
  const showGdprDialog =
    tenant === "SK" &&
    isInPersonChannel &&
    !pipe(existingClient, option.getOrElse(constFalse)) &&
    gdprType != GDPR_STATE.NO &&
    !isRework &&
    !gdprDialogDisplayed;
  function render() {
    return (
      <UserValidationWrapper
        authenticationMethod={props.authenticationMethod}
        skipValidation={props.isProspectClient && props.flowType === "TLSAgent"}
        onExit={option.some(() => {
          pipe(
            props.onExit,
            option.fold(constVoid, onExit => onExit(false))
          );
        })}
      >
        <>
          {pipe(
            state,
            foldState({
              Loading: () => (
                <Box hAlignContent="center">
                  <Loader />
                </Box>
              ),
              Error: state => (
                <ErrorBanner children={formatMessage(state.payload.message)} />
              ),
              Landing: () =>
                option.isSome(props.authenticationMethod) ? (
                  <StartNewFlow
                    keepClient
                    render={() => (
                      <Landing
                        onContinue={(
                          loanOffer: api.LoanOffer,
                          show3P?: boolean
                        ) =>
                          isClientFlow
                            ? dispatch(
                                existingClientSuspenseAction(
                                  option.some(loanOffer),
                                  false
                                )
                              )
                            : landingStep(loanOffer, show3P)
                        }
                        onAgeValidationFail={props.onAgeValidationFail}
                      />
                    )}
                  />
                ) : (
                  <Landing
                    onContinue={(loanOffer: api.LoanOffer, show3P?: boolean) =>
                      landingStep(loanOffer, show3P)
                    }
                    onAgeValidationFail={props.onAgeValidationFail}
                  />
                ),
              Select3P: state => (
                <Select3P
                  loanOffer={state.payload.loanOffer}
                  onContinue={(loanOffer: api.LoanOffer, show3P?: boolean) =>
                    landingStep(loanOffer, show3P)
                  }
                  onAgeValidationFail={props.onAgeValidationFail}
                  onBack={() => dispatch(goBackAction())}
                />
              ),
              PhoneAndEmailVerification: phoneAndEmailVerificationState => (
                <MainContent>
                  <PhoneAndEmailVerification
                    flowType={props.flowType}
                    coApplicant={option.none}
                    initialPromoCode={option.none}
                    onComplete={() =>
                      pipe(
                        phoneAndEmailVerificationState.payload.loanOffer,
                        option.fold(
                          () => dispatch(landing()),
                          loanOffer => dispatch(uploadIdAction(loanOffer))
                        )
                      )
                    }
                    onPhoneVerified={pipe(
                      props.onPhoneVerified,
                      option.fold(
                        () => readerTaskEither.fromIO(constVoid),
                        onPhoneVerified =>
                          readerTaskEither.fromTaskEither(onPhoneVerified)
                      ),
                      readerTaskEither.chain(() =>
                        readerTaskEither.fromTaskEither(runStartApplication())
                      )
                    )}
                    generateOTP={generateOtp}
                    verifyOTP={verifyOtp}
                    hideInstructions
                    onMaxNumberChangesReached={() =>
                      dispatch(maxNumberChangesReachedAction())
                    }
                  />
                </MainContent>
              ),
              AddPhoneNumber: () => (
                <ContactChange
                  data={{
                    contactType: "phoneNumber",
                    operationType: "edit",
                    value: option.none,
                    process: option.some("addPhoneNumberCF"),
                  }}
                  onExit={() =>
                    pipe(
                      props.onExit,
                      option.fold(constVoid, onExit => onExit(false))
                    )
                  }
                  onComplete={() => {
                    dispatch(reloadAction());
                  }}
                  onMaxNumberChangesReached={() =>
                    dispatch(maxNumberChangesReachedAction())
                  }
                />
              ),
              UploadID: state => (
                <MainContent>
                  <UploadId
                    onComplete={onCompleteUploadId(state.payload.loanOffer)}
                    onRefuse={reason => dispatch(existingClientAction(reason))}
                    onFailure={constVoid} // @TODO: handle upload failure.
                    onBack={() => dispatch(goBackAction())}
                    flowType={props.flowType}
                    authenticationMethod={props.authenticationMethod}
                    isThirdParty={is3P}
                  />
                </MainContent>
              ),
              ExistingClientSuspense: state => (
                <ExistingClientSuspense
                  onSuccess={
                    "TLSAgent" === props.flowType
                      ? checkNewestApplication(option.none, option.none)
                      : () =>
                          props.on3PExistingClient
                            ? props.on3PExistingClient(state.payload.loanOffer)
                            : pipe(
                                state.payload.loanOffer,
                                option.fold(constVoid, s =>
                                  isClientFlow
                                    ? goToCreditChecksWithMemorandum(s)()
                                    : dispatch(creditChecksAction(s))
                                )
                              )
                  }
                  onRequestFailure={reason =>
                    is3P
                      ? dispatch(
                          rejectAction("USER_VALIDATION_KO_REDIRECT_BRANCH")
                        )
                      : dispatch(rejectAction(reason))
                  }
                  hasPasswordForCommunication={true}
                  isPhoneNumberVerified={true}
                  isApplicationExisting={state.payload.isApplicationExisting}
                  flowType={props.flowType}
                  onExit={() =>
                    pipe(
                      props.onExit,
                      option.fold(constVoid, onExit => onExit(false))
                    )
                  }
                />
              ),
              CreditChecks: state => (
                <CreditChecks
                  loanOffer={state.payload.loanOffer}
                  onNext={({
                    personalDataOptions,
                    consents,
                    needsAdditionalIncome,
                    hasAdditionalIncome,
                    hasCounterOffer,
                  }) =>
                    pipe(
                      state.payload.rework.reworkSteps,
                      option.fold(
                        () =>
                          dispatchWithRejectionCheck(
                            customerOfferAction(
                              personalDataOptions.personalDataOptions,
                              consents
                            )
                          ),
                        () =>
                          needsAdditionalIncome && hasAdditionalIncome
                            ? dispatchWithRejectionCheck(
                                expensesAndAdditionalIncomeReworkAction(
                                  consents,
                                  state.payload.hasRefinancingCredits,
                                  option.some(true),
                                  option.some(true),
                                  personalDataOptions.personalDataOptions,
                                  state.payload.cpiAdditionalQuestions
                                )
                              )
                            : hasCounterOffer
                            ? dispatchWithRejectionCheck(
                                offerListReworkAction(
                                  "Counteroffer",
                                  personalDataOptions.personalDataOptions,
                                  consents
                                )
                              )
                            : dispatch(goToStepAction("ReworkNextStep"))
                      )
                    )
                  }
                  onApplicationRejected={rejectionReason =>
                    rejectionReason === "BonitaRejected"
                      ? dispatch(rejectAction("BonitaRejected"))
                      : dispatch(rejectAction("CBRejected"))
                  }
                  onCreditChecksConfirmed={() =>
                    setIsCreditChecksConfirmed(true)
                  }
                  initialConsent={state.payload.initialConsent}
                  getIncome={state.payload.getIncome}
                  flowType={props.flowType}
                  reworkData={state.payload.rework}
                  onExit={option.some(() => {
                    pipe(
                      props.onExit,
                      option.fold(constVoid, onExit => onExit(false))
                    );
                  })}
                  onChangeIncomeSourceType={value => {
                    setIncomeSourceType(
                      pipe(
                        value,
                        option.getOrElse(() => "Employed")
                      )
                    );
                  }}
                />
              ),
              ExistingClient: state => (
                <ExistingClient
                  onExit={() =>
                    pipe(
                      is3P,
                      boolean.fold(
                        () =>
                          props.onClientExists(
                            isPWSRemote && state.reason === "IsExisting"
                          ),
                        () =>
                          pipe(
                            props.onExit,
                            option.fold(constVoid, onExit => onExit(false))
                          )
                      )
                    )
                  }
                  flowType={props.flowType}
                  reason={state.reason}
                />
              ),
              ExistingApplication3PError: () =>
                pipe(
                  props.flowType,
                  foldFlowType({
                    when3P: () => (
                      <ExistingApplication3PError
                        onExit={() =>
                          pipe(
                            props.onExit,
                            option.fold(constVoid, onExit => onExit(false))
                          )
                        }
                      />
                    ),
                    whenHomeBanking: constNull,
                    whenSmartBanking: constNull,
                    whenInPerson: constNull,
                    whenTLSAgent: constNull,
                    whenPWSRemote: constNull,
                  })
                ),

              RestoreApplication: state => {
                const onNotRestore = pipe(
                  state.payload.parallelFlowParameters.hasCAFlowInProgress ||
                    state.payload.parallelFlowParameters.hasCAFlowSentToBO ||
                    state.payload.parallelFlowParameters.hasCFFlowSentToBO,
                  boolean.fold(
                    () =>
                      pipe(
                        deleteCurrentApplication(),
                        taskEither.chain(() =>
                          taskEither.fromIO(() => {
                            window.onunload = null;
                          })
                        ),
                        taskEither.chain(() =>
                          sendFeedback({ reason: "NONE" })
                        ),
                        taskEither.chain(() =>
                          pipe(
                            state.payload.loanOffer,
                            option.fold(
                              () => dispatchFirstStep(props.initialLoanOffer),
                              loanOffer =>
                                state.payload.isErrorFetchingRestoredData
                                  ? goToCreditChecksWithMemorandum(loanOffer)
                                  : pipe(
                                      state.payload.restoredData.existingClient,
                                      boolean.fold(
                                        () =>
                                          pipe(
                                            startNewFlow,
                                            taskEither.chain(() =>
                                              dispatchFirstStep(
                                                option.some(loanOffer)
                                              )
                                            )
                                          ),
                                        () =>
                                          goToCreditChecksWithMemorandum(
                                            loanOffer
                                          )
                                      )
                                    )
                            )
                          )
                        )
                      ),
                    () =>
                      pipe(
                        taskEither.right(constVoid),
                        taskEither.chain(() =>
                          sendFeedback({ reason: "NONE" })
                        ),
                        taskEither.chain(() =>
                          pipe(
                            state.payload.loanOffer,
                            option.fold(
                              () => dispatchFirstStep(props.initialLoanOffer),
                              loanOffer =>
                                state.payload.isErrorFetchingRestoredData
                                  ? goToCreditChecksWithMemorandum(loanOffer)
                                  : pipe(
                                      state.payload.restoredData.existingClient,
                                      boolean.fold(
                                        () =>
                                          pipe(
                                            startNewFlow,
                                            taskEither.chain(() =>
                                              dispatchFirstStep(
                                                option.some(loanOffer)
                                              )
                                            )
                                          ),
                                        () =>
                                          goToCreditChecksWithMemorandum(
                                            loanOffer
                                          )
                                      )
                                    )
                            )
                          )
                        )
                      )
                  )
                );
                return state.payload.isErrorFetchingRestoredData ? (
                  <RestoreDialog
                    isErrorFetchingRestoredData={
                      state.payload.isErrorFetchingRestoredData
                    }
                    onExit={() =>
                      pipe(
                        props.onExit,
                        option.fold(constVoid, onExit => onExit(false))
                      )
                    }
                    onNotRestore={onNotRestore}
                    parallelFlowParameters={
                      state.payload.parallelFlowParameters
                    }
                    onBack={onNotRestore}
                  />
                ) : (
                  <RestoreDialog
                    isErrorFetchingRestoredData={
                      state.payload.isErrorFetchingRestoredData
                    }
                    restoredData={extractRecapData(state.payload.restoredData)}
                    applicationStatus={state.payload.applicationStatus}
                    onExit={() =>
                      pipe(
                        props.onExit,
                        option.fold(constVoid, onExit => onExit(false))
                      )
                    }
                    onRestore={restoreWithDialog =>
                      state.payload.isErrorFetchingRestoredData
                        ? taskEither.fromIO(() => constVoid)
                        : onRestoreApplication(
                            state.payload.restoredData,
                            state.payload.rework,
                            restoreWithDialog
                          )
                    }
                    onNotRestore={onNotRestore}
                    parallelFlowParameters={
                      state.payload.parallelFlowParameters
                    }
                    onBack={onNotRestore}
                    isCredentialsAndSigningRemote={
                      props.isCredentialsAndSigningRemote
                    }
                    cardProviderChange={state.payload.cardProviderChange}
                    transitionToMasterCard={transitionToMasterCard}
                  />
                );
              },
              ApplicationLocked: () => (
                <ApplicationLockedPopup
                  onClose={() =>
                    pipe(
                      props.onExit,
                      option.fold(
                        () => constVoid,
                        onExit => onExit(false)
                      )
                    )
                  }
                />
              ),
              CustomerOffer: state => (
                <CustomerOffer
                  flowType={props.flowType}
                  onNext={hasRefinancingCredits =>
                    dispatchWithRejectionCheck(
                      expensesAndAdditionalIncomeAction(hasRefinancingCredits)
                    )
                  }
                  onBack={() => dispatchWithRejectionCheck(goBackAction())}
                  onExit={(isApplicationSaved: boolean) =>
                    pipe(
                      props.onExit,
                      option.fold(constVoid, onExit =>
                        onExit(isApplicationSaved)
                      )
                    )
                  }
                  onApplicationRejected={() =>
                    dispatch(rejectAction("CBRejected"))
                  }
                  onExposureExceeded={() =>
                    dispatch(rejectAction("ExposureExceeded"))
                  }
                  authenticationMethod={props.authenticationMethod}
                  restoredAdditionalQuestions={
                    state.payload.cpiAdditionalQuestions
                  }
                  clientExists={props.clientExists}
                />
              ),
              ExpensesAndAdditionalIncome: state => (
                <ExpensesAndAdditionalIncome
                  onNext={() => {
                    return pipe(
                      state.payload.rework.reworkSteps,
                      option.fold(
                        () =>
                          dispatchWithRejectionCheck(
                            offerListAction("Approved")
                          ),
                        () => dispatch(goToStepAction("ReworkNextStep"))
                      )
                    );
                  }}
                  onBack={() =>
                    pipe(
                      state.payload.rework.reworkSteps,
                      option.fold(
                        () => dispatchWithRejectionCheck(goBackAction()),
                        () => dispatch(goToStepAction("ReworkPreviousStep"))
                      )
                    )
                  }
                  onRejected={() => dispatch(rejectAction("LFRejected"))}
                  onCounterOffer={() =>
                    dispatchWithRejectionCheck(offerListAction("Counteroffer"))
                  }
                  onPending={() =>
                    dispatchWithRejectionCheck(checkExpensesOffersAction())
                  }
                  showAdditionalIncomeStep={pipe(
                    state.payload.needsAdditionalIncome &&
                      state.payload.hasAdditionalIncome,
                    option.getOrElse(constFalse)
                  )}
                  reworkData={state.payload.rework}
                />
              ),
              CheckAdditionalIncomes: state => (
                <AdditionalIncomesCheck
                  onFinish={hasIncomes =>
                    dispatch(
                      expensesAndAdditionalIncomeAction2(
                        hasIncomes
                          ? option.some(true)
                          : state.payload.needsAdditionalIncome,
                        hasIncomes
                          ? option.some(true)
                          : state.payload.hasAdditionalIncome
                      )
                    )
                  }
                  onError={constVoid}
                />
              ),
              CheckExpensesOffers: () => (
                <CheckExpensesOffers
                  onApproved={() =>
                    dispatchWithRejectionCheck(sendAdditionalIncomeAction())
                  }
                  onRejected={() => dispatch(rejectAction("LFRejected"))}
                  onError={() => dispatchWithRejectionCheck(goBackAction())}
                />
              ),
              SendAdditionalIncome: state => (
                <SendAdditionalIncome
                  onApproved={() =>
                    pipe(
                      state.payload.rework.reworkSteps,
                      option.fold(
                        () =>
                          dispatchWithRejectionCheck(
                            offerListAction("Approved")
                          ),
                        () =>
                          dispatchWithRejectionCheck(
                            saveCustomerOfferSuspenseAction("Approved")
                          )
                      )
                    )
                  }
                  onCounterOffer={() =>
                    dispatchWithRejectionCheck(offerListAction("Counteroffer"))
                  }
                  onRejected={() => dispatch(rejectAction("LFRejected"))}
                  onError={() => dispatchWithRejectionCheck(goBackAction())}
                />
              ),
              OfferList: state => (
                <OfferList
                  offerType={state.payload.offerType}
                  onContinue={flow(
                    offerReviewAction,
                    dispatchWithRejectionCheck
                  )}
                  onBack={() => dispatchWithRejectionCheck(goBackAction())}
                />
              ),
              SaveCustomerOfferSuspense: () => (
                <SaveCustomerOfferSuspense
                  onFailure={() => dispatchWithRejectionCheck(goBackAction())}
                  onContinue={data =>
                    dispatchWithRejectionCheck(offerReviewAction(data))
                  }
                />
              ),
              OfferReview: state => (
                <MainContent>
                  <OfferReview
                    onBack={() => dispatchWithRejectionCheck(goBackAction())}
                    onComplete={handleAfterOfferReview(
                      state.payload.hasRefinancingCredits,
                      state.payload.loanOffer
                    )}
                    selectedOffer={state.payload.selectedOffer}
                    onApplicationRejected={(rejectionReason: RejectionReason) =>
                      dispatch(rejectAction(rejectionReason))
                    }
                    authenticationMethod={props.authenticationMethod}
                    existingClient={pipe(
                      existingClient,
                      option.getOrElse(() => props.clientExists)
                    )}
                  />
                </MainContent>
              ),
              OfferReviewPush: state => (
                <MainContent>
                  <OfferReviewPush
                    onBack={() => dispatchWithRejectionCheck(goBackAction())}
                    onComplete={() =>
                      dispatch(
                        followUpAndSignatureAction(
                          state.payload.hasRefinancingCredits,
                          false // TODO Check if needed somewhere
                        )
                      )
                    }
                    selectedOffer={state.payload.selectedOffer}
                    onApplicationRejected={(rejectionReason: RejectionReason) =>
                      dispatch(rejectAction(rejectionReason))
                    }
                    authenticationMethod={props.authenticationMethod}
                    existingClient={pipe(
                      existingClient,
                      option.getOrElse(() => props.clientExists)
                    )}
                    disbursementAccount={state.payload.disbursementAccount}
                  />
                </MainContent>
              ),
              PackageSelection: state => (
                <MainContent>
                  <PackagesSelection
                    chosenPackage={option.none}
                    onPackageConfirm={option.some(packageType =>
                      onChooseCard(packageType, state.payload.loanOffer)
                    )}
                    child={false}
                    isCf={true}
                    onBack={() => dispatchWithRejectionCheck(goBackAction())}
                  />
                </MainContent>
              ),
              VirtualCard: state => (
                <MainContent>
                  <VirtualCardsSL
                    onSubmit={handleAfterVirtualCard(
                      state.payload.hasRefinancingCredits
                    )}
                    onChange={data =>
                      savePhysicalCard({
                        checkboxValue: option.isSome(data.physicalCardSelected)
                          ? data.physicalCardSelected.value
                          : false,
                      })()
                    }
                    onBack={() => dispatchWithRejectionCheck(goBackAction())}
                  />
                </MainContent>
              ),
              AccountsForRefinancing: state => (
                <AccountsForRefinancing
                  existingClient={pipe(
                    props.authenticationMethod,
                    option.fold(constFalse, constTrue)
                  )}
                  onConfirm={() =>
                    pipe(
                      state.payload.rework.reworkSteps,
                      option.fold(
                        handleKyc(state.payload.hasRefinancingCredits),
                        taskEither.fromIO(() =>
                          dispatch(goToStepAction("ReworkNextStep"))
                        )
                      )
                    )
                  }
                  onBack={() =>
                    pipe(
                      state.payload.rework.reworkSteps,
                      option.fold(
                        () => dispatchWithRejectionCheck(goBackAction()),
                        () => dispatch(goToStepAction("ReworkPreviousStep"))
                      )
                    )
                  }
                  reworkData={state.payload.rework}
                />
              ),
              KYC: state => (
                <KYC
                  mainIncomeSource={incomeSourceType as IncomeSourceType}
                  onNext={needsMicropayment =>
                    pipe(
                      needsMicropayment,
                      boolean.fold(
                        () =>
                          dispatchWithRejectionCheck(
                            followUpAndSignatureAction(
                              state.payload.hasRefinancingCredits,
                              true
                            )
                          ),
                        () => dispatchWithRejectionCheck(micropaymentAction())
                      )
                    )
                  }
                  onBack={() => dispatchWithRejectionCheck(goBackAction())}
                  flowType={props.flowType}
                  transactionsInfo={state.payload.transactionsInfo}
                  existingClient={isExistingClient}
                />
              ),
              Micropayment: state => (
                <MicroTransaction
                  onNext={() =>
                    dispatchWithRejectionCheck(
                      followUpAndSignatureAction(
                        state.payload.hasRefinancingCredits,
                        true
                      )
                    )
                  }
                  onBack={() => dispatchWithRejectionCheck(goBackAction())}
                />
              ),
              MicropaymentOverview: () => <MicroTransactionOverview />,
              ReloadSuspense: state => (
                <ReloadSuspense
                  onReload={pipe(
                    resetCredentialsProcess(),
                    taskEither.chain(() =>
                      taskEither.fromIO(() =>
                        dispatchWithRejectionCheck(
                          followUpAndSignatureAction(
                            state.payload.hasRefinancingCredits,
                            true
                          )
                        )
                      )
                    )
                  )}
                />
              ),
              AfterSignature: state => (
                <AfterSignature
                  onContinue={() =>
                    pipe(
                      state.payload.rework.reworkSteps,
                      option.fold(
                        () =>
                          "TLSAgent" === props.flowType
                            ? taskEither.fromIO(() =>
                                dispatchWithRejectionCheck(
                                  summaryAction(
                                    state.payload.selectedOffer.productType,
                                    state.payload.hasRefinancingCredits,
                                    state.payload.lfStatus
                                  )
                                )
                              )
                            : pipe(
                                getRequiredDocumentsReceived(),
                                taskEither.bimap(
                                  () =>
                                    dispatch(
                                      errorAction(
                                        "StandardLoan.UploadDocuments.requiredDocuments.notReady"
                                      )
                                    ),
                                  ({ requiredDocumentsReceived }) =>
                                    pipe(
                                      requiredDocumentsReceived,
                                      boolean.fold(
                                        () => {
                                          dispatch(
                                            errorAction(
                                              "StandardLoan.UploadDocuments.requiredDocuments.notReady"
                                            )
                                          );
                                        },
                                        () =>
                                          pipe(
                                            requiredDocuments(),
                                            taskEither.bimap(
                                              () =>
                                                dispatchWithRejectionCheck(
                                                  summaryAction(
                                                    state.payload.selectedOffer
                                                      .productType,
                                                    state.payload
                                                      .hasRefinancingCredits,
                                                    state.payload.lfStatus
                                                  )
                                                ),
                                              list =>
                                                list.length > 0
                                                  ? dispatchWithRejectionCheck(
                                                      uploadDocumentsAction(
                                                        state.payload.lfStatus
                                                      )
                                                    )
                                                  : dispatchWithRejectionCheck(
                                                      summaryAction(
                                                        state.payload
                                                          .selectedOffer
                                                          .productType,
                                                        state.payload
                                                          .hasRefinancingCredits,
                                                        state.payload.lfStatus
                                                      )
                                                    )
                                            )
                                          )()
                                      )
                                    )
                                )
                              ),
                        () =>
                          taskEither.fromIO(() =>
                            dispatchWithRejectionCheck(
                              summaryAction(
                                state.payload.selectedOffer.productType,
                                state.payload.hasRefinancingCredits,
                                state.payload.lfStatus
                              )
                            )
                          )
                      )
                    )()
                  }
                  onRetrySignature={taskEither.fromIO(() =>
                    dispatch(
                      followUpAndSignatureAction(
                        state.payload.hasRefinancingCredits,
                        state.payload.isKycNeeded,
                        false
                      )
                    )
                  )}
                />
              ),
              FollowUpAndSignature: state => (
                <AuthMethodCalculateBySignatureType
                  defaultAuthenticationMethod={props.authenticationMethod}
                  flowType={props.flowType}
                  renderChild={signatureType => (
                    <FollowUpAndSignature
                      flowType={props.flowType}
                      onExit={() =>
                        pipe(
                          props.onExit,
                          option.fold(constVoid, onExit => onExit(true))
                        )
                      }
                      onRemoteCredentialsExit={() => {
                        dispatch({ type: "ReloadSuspense" });
                      }}
                      onAfterSignature={lfStatus => {
                        dispatch({
                          type: "AfterSignature",
                          payload: { lfStatus: lfStatus },
                        });
                      }}
                      onContinue={lfStatus =>
                        pipe(
                          state.payload.rework.reworkSteps,
                          option.fold(
                            () =>
                              "TLSAgent" === props.flowType
                                ? taskEither.fromIO(() =>
                                    dispatchWithRejectionCheck(
                                      summaryAction(
                                        state.payload.selectedOffer.productType,
                                        state.payload.hasRefinancingCredits,
                                        lfStatus
                                      )
                                    )
                                  )
                                : pipe(
                                    getRequiredDocumentsReceived(),
                                    taskEither.bimap(
                                      () =>
                                        dispatch(
                                          errorAction(
                                            "StandardLoan.UploadDocuments.requiredDocuments.notReady"
                                          )
                                        ),
                                      ({ requiredDocumentsReceived }) =>
                                        pipe(
                                          requiredDocumentsReceived,
                                          boolean.fold(
                                            () => {},
                                            () =>
                                              pipe(
                                                requiredDocuments(),
                                                taskEither.bimap(
                                                  () =>
                                                    dispatchWithRejectionCheck(
                                                      summaryAction(
                                                        state.payload
                                                          .selectedOffer
                                                          .productType,
                                                        state.payload
                                                          .hasRefinancingCredits,
                                                        lfStatus
                                                      )
                                                    ),
                                                  list =>
                                                    list.length > 0
                                                      ? dispatchWithRejectionCheck(
                                                          uploadDocumentsAction(
                                                            lfStatus
                                                          )
                                                        )
                                                      : dispatchWithRejectionCheck(
                                                          summaryAction(
                                                            state.payload
                                                              .selectedOffer
                                                              .productType,
                                                            state.payload
                                                              .hasRefinancingCredits,
                                                            lfStatus
                                                          )
                                                        )
                                                )
                                              )()
                                          )
                                        )
                                    )
                                  ),
                            () =>
                              taskEither.fromIO(() =>
                                dispatchWithRejectionCheck(
                                  summaryAction(
                                    state.payload.selectedOffer.productType,
                                    state.payload.hasRefinancingCredits,
                                    lfStatus
                                  )
                                )
                              )
                          )
                        )()
                      }
                      onApplicationRejected={() =>
                        dispatch(rejectAction("LFRejected"))
                      }
                      authenticationMethod={
                        option.isSome(signatureType)
                          ? mapSignatureToExistingClientAuthMethod(
                              signatureType.value,
                              props.flowType,
                              props.authenticationMethod
                            )
                          : props.authenticationMethod
                      }
                      rework={state.payload.rework}
                      credentialStatus={pipe(
                        props.restoreDataObject,
                        option.fold<RestoreDataObjectSL, CredentialStatus>(
                          () =>
                            pipe(
                              state.payload.restoredData,
                              option.fold<
                                api.RestoreNewestLoanApplicationOutput,
                                CredentialStatus
                              >(
                                () => ({
                                  type: props.clientExists
                                    ? "existingClient"
                                    : "unknown",
                                }),
                                r =>
                                  r.existingClient
                                    ? { type: "existingClient" }
                                    : {
                                        type: "newClient",
                                        shouldAskCredential:
                                          state.payload.shouldAskCredential,
                                      }
                              )
                            ),
                          r =>
                            r.restoreData.existingClient
                              ? { type: "existingClient" }
                              : {
                                  type: "newClient",
                                  shouldAskCredential:
                                    state.payload.shouldAskCredential,
                                }
                        )
                      )}
                      existingClient={pipe(
                        existingClient,
                        option.getOrElse(() => props.clientExists)
                      )}
                      isCredentialsAndSigningRemote={
                        props.isCredentialsAndSigningRemote as boolean
                      }
                      isKycNeeded={state.payload.isKycNeeded}
                    />
                  )}
                />
              ),
              Rejected: state => (
                <MainContent>
                  <FinalFeedback
                    decision="Rejected"
                    rejectionReason={state.payload.rejectionReason}
                    flowType={props.flowType}
                    onGotoClientsPage={option.some(() => {
                      pipe(
                        props.onExit,
                        option.fold(constVoid, onExit => onExit(false))
                      );
                    })}
                  />
                </MainContent>
              ),
              Summary: state =>
                pipe(
                  isTLS,
                  boolean.fold(
                    () => (
                      <SLSummary
                        productType={state.payload.productType}
                        lfStatus={pipe(
                          state.payload.lfStatus,
                          option.fold(
                            () => "Approved",
                            status => status
                          )
                        )}
                        hasRefinancing={state.payload.hasRefinancing}
                        goToClient={() =>
                          pipe(
                            props.onExit,
                            option.fold(constVoid, onExit => onExit(false))
                          )
                        }
                        clientExists={props.clientExists}
                        goToMicroPayments={() =>
                          dispatch(micropaymentOverviewAction())
                        }
                        flowType={props.flowType}
                      />
                    ),
                    () => (
                      <ContractsSent
                        onGoHp={option.some(() => {
                          pipe(
                            props.onExit,
                            option.fold(constVoid, onExit => onExit(false))
                          );
                        })}
                      />
                    )
                  )
                ),
              UploadDocuments: state => (
                <UploadRequiredDocuments
                  isClientFlow={isClientFlow}
                  onNext={pipe(
                    confirmUpload(),
                    taskEither.chain(() =>
                      taskEither.fromIO(() => {
                        return pipe(
                          state.payload.rework.reworkSteps,
                          option.fold(
                            () =>
                              dispatchWithRejectionCheck(
                                summaryAction(
                                  state.payload.productType,
                                  state.payload.hasRefinancing,
                                  state.payload.lfStatus
                                )
                              ),
                            () => dispatch(goToStepAction("ReworkNextStep"))
                          )
                        );
                      })
                    )
                  )}
                  onNextWithoutConfirm={taskEither.fromIO(() =>
                    pipe(
                      state.payload.rework.reworkSteps,
                      option.fold(
                        () =>
                          dispatchWithRejectionCheck(
                            summaryAction(
                              state.payload.productType,
                              state.payload.hasRefinancing,
                              state.payload.lfStatus
                            )
                          ),
                        () => dispatch(goToStepAction("ReworkNextStep"))
                      )
                    )
                  )}
                  bankerFlowId={option.none}
                  productType={option.some("SL")}
                  onApplicationRejected={() =>
                    dispatch(rejectAction("CBRejected"))
                  }
                  isRework={state.payload.rework !== emptyRework}
                  requiredDocumentsUpToDate={
                    state.payload.requiredDocumentsUpToDate
                  }
                />
              ),
              PersonalData: state =>
                pipe(
                  state.payload.extractedData,
                  option.fold(constNull, clientData => (
                    <ConfirmClientData
                      clientData={clientData}
                      reworkData={state.payload.rework.oldValues}
                      onContinue={() =>
                        dispatch(goToStepAction("ReworkNextStep"))
                      }
                    />
                  ))
                ),
              MaxNumberChangesReached: () => (
                <MainContent>
                  <FeedbackBlock
                    type="negative"
                    size="large"
                    heading={formatMessage(
                      "StandardLoan.MaxNumberChangesReached.Title"
                    )}
                    subheading={option.some(
                      formatMessage("StandardLoan.MaxNumberChangesReached")
                    )}
                    actions={
                      isInPersonChannel && !is3PChannel
                        ? []
                        : [
                            {
                              variant: "primary",
                              label: formatMessage(
                                "StandardLoan.MaxNumberChangesReached.Button"
                              ),
                              action: navigateToFindBranches,
                            },
                          ]
                    }
                  />
                </MainContent>
              ),
            })
          )}
          {showGdprDialog && (
            <GDPR
              onDismiss={pipe(
                props.onExit,
                option.fold(
                  () => () => {},
                  onExit => () => {
                    if (shouldShowFormsOnExit(state, isCreditChecksConfirmed)) {
                      setIsExiting(true);
                    } else {
                      closeApplication()();
                      onExit(false, reasonNotSavedOnExit(state));
                    }
                  }
                )
              )}
              onContinue={() => {
                setGdprDialogDisplayed(true);
              }}
              paperBased={gdprType == GDPR_STATE.PAPER_BASED}
            />
          )}
        </>
      </UserValidationWrapper>
    );
  }

  return pipe(
    props.onExit,
    option.fold(
      () => (
        <MainLayout
          title={option.some(formatMessage("StandardLoan.Landing.Title"))}
          backgroundImageURL={option.none}
          onLogoClick={option.none}
          iconAction={pipe(
            option.some(() => {
              pipe(
                props.onExit,
                option.fold(constVoid, onExit => onExit(false))
              );
            }),
            option.map(headerActionIcon.exit)
          )}
          steps={getSteps()}
          onStepClick={constVoid}
        >
          <PortalStatusAlert inAuthenticatedPage />
          {render()}
        </MainLayout>
      ),
      onExit => (
        <ExitProcessDialogWrapper
          isExiting={isExiting}
          onExitAction={() => {
            if (shouldShowFormsOnExit(state, isCreditChecksConfirmed)) {
              setIsExiting(true);
            } else {
              closeApplication()();
              onExit(false, reasonNotSavedOnExit(state));
            }
          }}
          onProcessExit={isApplicationSaved => {
            setIsExiting(false);
            onExit(isApplicationSaved);
          }}
          onDismiss={() => setIsExiting(false)}
          title={option.some(formatMessage("StandardLoan.Landing.Title"))}
          backgroundImageURL={option.none}
          steps={getSteps()}
          onStepClick={goToStep}
        >
          {render()}
        </ExitProcessDialogWrapper>
      )
    )
  );
}

function convertReworkStepToState(
  reworkStep: reworkApi.ReworkStep
): State["type"] {
  switch (reworkStep) {
    case "ACCOUNTS_FOR_REFINANCING":
      return "AccountsForRefinancing";
    case "ADDITIONAL_INCOME":
      return "ExpensesAndAdditionalIncome";
    case "AUTHORIZATION_PAGE":
      return "FollowUpAndSignature";
    case "INCOME_AND_PERSONAL_DATA":
      return "CreditChecks";
    case "PERSONAL_DATA":
      return "PersonalData";
    case "UPLOAD_DOCUMENTS":
      return "UploadDocuments";
  }
}

function convertReworkStepArray(
  reworkSteps: Option<NonEmptyArray<reworkApi.ReworkStep>>
): Option<NonEmptyArray<State["type"]>> {
  return pipe(
    reworkSteps,
    option.map(nonEmptyArray.map(step => convertReworkStepToState(step)))
  );
}

function stepVisibilityFromReworkSteps(
  state: State
): Option<NonEmptyArray<State["type"]>> {
  switch (state.type) {
    case "AccountsForRefinancing":
    case "CreditChecks":
    case "CustomerOffer":
    case "ExpensesAndAdditionalIncome":
    case "FollowUpAndSignature":
    case "OfferList":
    case "CheckExpensesOffers":
    case "SendAdditionalIncome":
    case "OfferReview":
    case "KYC":
    case "PersonalData":
    case "SaveCustomerOfferSuspense":
    case "UploadDocuments":
      return convertReworkStepArray(state.payload.rework.reworkSteps);
    case "RestoreApplication":
      return convertReworkStepArray(
        !state.payload.isErrorFetchingRestoredData
          ? state.payload.rework.reworkSteps
          : option.none
      );
    case "ExistingApplication3PError":
    case "ExistingClient":
    case "Landing":
    case "Loading":
    case "Micropayment":
    case "PhoneAndEmailVerification":
    case "Select3P":
    case "AddPhoneNumber":
    case "Rejected":
    case "MicropaymentOverview":
    case "Summary":
    case "MaxNumberChangesReached":
    case "UploadID":
    case "OfferReviewPush":
    case "ReloadSuspense":
    case "ExistingClientSuspense":
    case "CheckAdditionalIncomes":
    case "AfterSignature":
    case "ApplicationLocked":
    case "Error":
    case "PackageSelection":
    case "VirtualCard":
      return option.none;
  }
}
